import React from "react";
// Customizable Area Start
import SidebarLayout from "../../SidebarLayout.web";
import { Box, styled } from "@material-ui/core";
import MaterialDetailsController, {
  Props
} from "./MaterialDetailsController.web";
import PageContentLayout from "../../PageContentLayout.web";
//@ts-ignore
import { Document, Page , pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import FileViewer from 'react-file-viewer';
// Customizable Area End

export default class MaterialDetails extends MaterialDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { numPages } = this.state;
    return (
      // Customizable Area Start
      <>
        <SidebarLayout {...this.props}>
          <PageContentLayout
            className="boxHide"
            goBack={this.goBack}
            data-test-id="goBackTestId"
          >
            <IframeStyle>    
                <Box
                  className="pdfBoxStyle"
                >
                {this.state.fileType === "pdf" &&
                  <Document
                    className="documentStyle"
                    file={this.state.modifiedPdfUrl}
                    data-test-id="modifiedPdfTestId"
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    externalLinkTarget="_self"
                  >
                    {Array.from(new Array(numPages), (pagenumber, numPageIndex) => (
                      <Page
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        key={`page_${numPageIndex + 1}`}
                        pageNumber={numPageIndex + 1}
                        scale={1.5}
                      />
                    ))}
                  </Document>
                }
                  {(this.state.modifiedPdfUrl &&  this.state.fileType === "docx") && (
                    <FileViewer
                      className="documentStyles"
                      fileType={this.state.fileType}
                      filePath={this.state.modifiedPdfUrl}
                      disableLoader={false}
                      onLoad={false}
                    />
                  )}
                </Box>
              <Box
                data-test-id="disableStyle"
                className="boxStyle"
                onContextMenu={this.disableContextMenu}
              />
            </IframeStyle>
          </PageContentLayout>
        </SidebarLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const IframeStyle = styled(Box)({
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  position:"relative",
  "& .file-viewer-loader":{
    display:" none !important"
  },
  "& .boxHides": {
    position: "absolute",
    height: "50px",
    bottom: "0px",
    width: "100%",
    background: "#d6dad6"
  },
  "& .documentStyle": {
    height: "97vh",
    overflowX: "auto",
    overflow:"auto",
    maxWidth: "100%", 
    "& .file-viewer-loader":{
      display:" none !important"
    },
    "& .pdf-loading":{
      display:"none"
    },
  },
  "& .documentStyles": {
    height: "97vh",
    overflowX: "auto",
    maxWidth: "93%", 
  },
  "& .pdfBoxStyle":{
      width: "93%",
      height: "100vh",     
  },
  "& .fileViewer":{
    maxHeight: "90vh",  
    maxWidth: "80vw", 
  }
});
// Customizable Area End
