import React from "react";
// Customizable Area Start
import { Box, Typography, Paper } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CalendarCard } from "./ProgramScheduler/Calendar.web";
import { UpcomingEvents } from "./ProgramScheduler/UpcomingEvents.web";
import { EventData } from "./DashboardController";
// Customizable Area End
const configJSON = require("./config");
// Customizable Area Start
interface EventAttributes {
  date: string;
  time: string;
  name: string;
  organizer: Organizer
}

interface Organizer {
  name: string;
  profile_photo_url: string;
}

interface E {
  id: string;
  type: string;
  attributes: EventAttributes;
}

type RightLayoutProps = {
  listView: boolean;
  guestView?: boolean;
  upcomingEventsList: Array<E>;
  isDashboard?: boolean;
  currentDate: Date;
  openModal: (eventData: EventData) => void;
  handleDateChange: () => void;
};

const useStyles = makeStyles<Theme, RightLayoutProps>((_theme: Theme) => ({
  layout: {
    display: (props: RightLayoutProps) => (props.listView || props.guestView ? "none" : "block"),
    justifyContent: "center",
    width: '30%',
    marginTop: '0px',
    '@media(max-width:960px)': {
      width: '100%'
    },
    '@media(max-width:600px)': {
      width: '100%'
    },
    '@media(max-width:480px)': {
      width: '100%'
    }
  },
  container: {
    padding: 0,
    marginTop: 0,
    marginRight: 0,
    maxWidth: '100%',
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: '2px 4px 16px 0px rgba(165, 176, 194, 0.24)'
  },
  upcomingEventsBox: {
    marginTop: 24,
    paddingBottom: 14,
    marginBottom: 4,
    borderBottom: "1px solid #0056A3"
  },
  eventList: {
    maxHeight: 469,
    overflow: 'scroll'
  },
  upcomingEventsTitle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#0056A3",
    marginLeft: 10,
    '@media(max-width:767px)': {
      fontSize: '18px'
    }
  }
}));
// Customizable Area End
const RightLayout = (props: RightLayoutProps) => {
  // Customizable Area Start
  const classes = useStyles(props);
  const _upcomingEventsList = props?.upcomingEventsList;

  return (
    <>
      <Box hidden={!(!props.listView && !props.guestView)} className={classes.layout}>
        <Paper className={classes.container}>
          <CalendarCard currentDate={props.currentDate} handleDateChange={props.handleDateChange}/>
          <Box className={classes.upcomingEventsBox}>
            <Typography className={classes.upcomingEventsTitle}>
              {configJSON.Dashboard.UpcomingEvents}
            </Typography>
          </Box>
          <Box className={classes.eventList} data-test-id="calendar-event-list">
          {_upcomingEventsList?.length > 0 ? (
            _upcomingEventsList.sort((a: any, b: any) => {
              const a1 = a.attributes.date.split('-');
              const b1 = b.attributes.date.split('-');
              return Math.abs(Date.now() - new Date(Number(a1[2]), Number(a1[0]) - 1, Number(a1[1])).getTime()) - Math.abs(Date.now() - new Date(Number(b1[2]), Number(b1[0]) - 1, Number(b1[1])).getTime());
            }).map((e: E) => {
              return (
                <UpcomingEvents
                  openModal={props.openModal}
                  key={e.id}
                  eventsData={e}
                />
              );
            })
          ) : (
            <Typography style={{ marginLeft: '12px', padding: '10px 0' }} >{configJSON.Dashboard.NoUpcomingEvents}</Typography>
          )}
          </Box>
        </Paper>
      </Box>
    </>
  );
  // Customizable Area End
};

export default RightLayout;
