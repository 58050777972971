import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";

let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");
import { participationIcon, focusIcon, collaborationIcon, respectIcon, initiativeIcon, positiveAttitudeIcon, criticalThinkingIcon, perseveranceIcon, creativityIcon, empathyIcon } from "../../assets";


// Customizable Area Start

const ratings = [
  { name: configJSON.TimelyProgress.Participation, rating: "1", icon: participationIcon, key: configJSON.TimelyProgress.ParticipationKey },
  { name: configJSON.TimelyProgress.Focus, rating: "1", icon: focusIcon, key: configJSON.TimelyProgress.FocusKey },
  { name: configJSON.TimelyProgress.Collaboration, rating: "1", icon: collaborationIcon, key: configJSON.TimelyProgress.CollaborationKey },
  { name: configJSON.TimelyProgress.Respect, rating: "1", icon: respectIcon, key: configJSON.TimelyProgress.RespectKey },
  { name: configJSON.TimelyProgress.Initiative, rating: "1", icon: initiativeIcon, key: configJSON.TimelyProgress.InitiativeKey },
  { name: configJSON.TimelyProgress.PositiveAttitude, rating: "1", icon: positiveAttitudeIcon, key: configJSON.TimelyProgress.PositiveAttitudeKey },
  { name: configJSON.TimelyProgress.CriticalThinking, rating: "1", icon: criticalThinkingIcon, key: configJSON.TimelyProgress.CriticalThinkingKey },
  { name: configJSON.TimelyProgress.Perseverance, rating: "1", icon: perseveranceIcon, key: configJSON.TimelyProgress.PerseveranceKey },
  { name: configJSON.TimelyProgress.Creativity, rating: "1", icon: creativityIcon, key: configJSON.TimelyProgress.CreativityKey },
  { name: configJSON.TimelyProgress.Empathy, rating: "1", icon: empathyIcon, key: configJSON.TimelyProgress.EmpathyKey },
]
const commonStateTpr = {
  selectedGradesAttendanceFirst: [{ id: 1, value: "", key: 'H49CH1', attendance: "" }],
  academic_year: "",
  programTrack: "",
  module_names: [{ id: 1, module: "" }, { id: 2, module: "" }],
  studentsFeedback: [{ id: 1, message: "" }],
  parentsFeedback: [{ id: 1, message: "" }],
  educatorsFeedback: [{ id: 1, message: "" }],
  recommendations: "",
  acknowledgement: "",
  ratings: [...ratings],
  selectedSchool: { name: "", id: 0 },
  selectedCourse: { name: "", id: 0, overview: "" },
}

interface Academic {
  value: string;
  id: string; 
  title: string;
}

interface FedbackType{
  id: number,
  message: string
}

interface CourseDetails {
  id: number;
  title: string;
  module_names: string[];
  overview: string;
  academic_year: string[];
}

interface Courses {
  type: string;
  id: string;
  attributes: CourseDetails;
}

interface TransformeAcademic {
  value: string;
  title: string;
  id: string;
}

export interface AttendanceRecord {
  id: number;
  value: string;
  key: string;
  attendance: string;
}

interface GradeAttendance {
  total: number;
  count: number;
}

interface Result {
  [key: string]: number;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  currentPage: string;
  pageNo: any;
  reportData: any;
  user_token: any;
  schoolCourseData: any;
  schoolList: any;
  courseList: any;
  gradeList: any;
  selectedGradesAttendanceFirst: AttendanceRecord[];
  selectedSchool: any;
  selectedCourse: any;
  academic_year: string;
  programTrack: string;
  module_names: any;
  studentsFeedback: any;
  parentsFeedback: any;
  educatorsFeedback: any;
  recommendations: string;
  acknowledgement: string;
  ratings: any;
  reportSubmittedModal: boolean;
  deleteModal: boolean;
  snackBarIsOpen: boolean;
  snackBarMsgTpr: any;
  deleteReportId: string;
  editReportId: string;
  validationErrors: any;
  editMode: boolean;
  academicYearList:Array<Academic>;
  // Customizable Area End
}
interface SS { id: any }

export default class TimelyProgressReportController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCourseListApiCallId: any;
  PostSubmitFormapiCallId: any;
  GetReportDataApiCallId: any;
  DeleteReportApiCallId: any;
  EditReportApiCallId: any;
  GetGradeListApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      ...commonStateTpr,
      currentPage: "TprList",
      pageNo: 1,
      reportData: {},
      user_token: "",
      schoolCourseData: {},
      schoolList: [],
      courseList: [],
      gradeList: [],
      reportSubmittedModal: false,
      deleteModal: false,
      snackBarIsOpen: false,
      snackBarMsgTpr: "",
      deleteReportId: "",
      editReportId: "",
      validationErrors: {
        selectedCourse: false,
        selectedSchool: false,
        academicYear: false,
        programTrackerLength: false,
        programTrack: false,
        moduleNames: false,
        recommendations: false,
        acknowledgement: false,
        attendanceFirst: false,
        attendanceSecond: false,
        studentsFeedback: false,
        parentsFeedback: false,
        educatorsFeedback: false,
        studentFeedbackLength: false,
        parentFeedbackLength:false,
        educatorFeedbackLength: false,
        studentFeedbackCharacter: false,
        parentFeedbackCharacter:false,
        educatorFeedbackCharacter: false,
      },
      editMode: false,
      academicYearList:[]
    };
    this.handleAddNewAttendance = this.handleAddNewAttendance.bind(this);
    this.calculateAverageAttendance = this.calculateAverageAttendance.bind(this);
    this.generateUniqueAlphanumericString = this.generateUniqueAlphanumericString.bind(this);
    this.handleRemoveAttendance = this.handleRemoveAttendance.bind(this);
    this.handleReportRefresh = this.handleReportRefresh.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const user_token = await localStorage.getItem("user_token") ?? "";
    this.setState({
      user_token: user_token
    });

    this.getCourseListTpr();
    this.getReportDataTpr();

  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorMsg = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorMsg) {

        this.setState({ snackBarIsOpen: true, snackBarMsgTpr: "Error: Unable to create report." });
      }
      if (responseJson && !responseJson.errors) {
        this.ApiCallSuuccess(responseJson, apiRequestCallId);

      } else if (responseJson && responseJson.errors) {
        console.log(responseJson, "Error");
        this.setState({ snackBarIsOpen: true, snackBarMsgTpr: responseJson.errors[0] });
      }
    }

  }

  ApiCallSuuccess(responseJson: any, apiRequestCallId: any) {
    if (apiRequestCallId === this.GetCourseListApiCallId) {
      const schoolDataTpr = responseJson.schools.data.map((schoolObjTpr: any) => {
        return { title: schoolObjTpr.attributes.name, value: schoolObjTpr.attributes.name, id: schoolObjTpr.attributes.id }
      })
      const courseDataTpr = responseJson.mind_mastery_courses.data.map((courseObjTpr: any) => {
        return { title: courseObjTpr.attributes.title, value: courseObjTpr.attributes.title, id: courseObjTpr.attributes.id }
      })

      this.setState({
        schoolCourseData: responseJson,
        schoolList: [...schoolDataTpr],
        courseList: [...courseDataTpr]
      })

    }
    if (apiRequestCallId === this.GetReportDataApiCallId) {

      this.setState({ reportData: responseJson });
    }

    if (apiRequestCallId === this.PostSubmitFormapiCallId) {
      this.setState({ reportSubmittedModal: true, editMode: false, editReportId: "" });
    }
    if (apiRequestCallId === this.DeleteReportApiCallId) {
      this.setState({ deleteModal: false });
      this.getReportDataTpr();
    }

    if (apiRequestCallId === this.GetGradeListApiCallId) {
      let newGradeList = responseJson.sections?.length && responseJson.sections.map((grade: string) => {
        return { title: grade, value: grade }
      })
      this.setState({ gradeList: newGradeList })
    }

    if (apiRequestCallId === this.EditReportApiCallId) {
      this.setState({ editMode: true });

      let attendanceFirst = Object.entries(responseJson.report.attributes.attendance_percent).map(([key, value], index): AttendanceRecord => {
        return { id: index + 1, value: key, key: this.generateUniqueAlphanumericString(), attendance: value as string };
      });

      const school = this.state.schoolCourseData.schools.data.filter((el: any) => {
        return el.attributes.id === responseJson.report.attributes.school_id
      })

      const course = this.state.schoolCourseData.mind_mastery_courses.data.filter((course: any) => {
        return course.attributes.id === responseJson.report.attributes.mind_mastery_course_id;
      })

      const studentFeedback = responseJson.report.attributes["360_feedback"].students.map((el: any, index: number) => {
        return { id: index + 1, message: el }
      })

      const parentsFeedback = responseJson.report.attributes["360_feedback"].parents.map((el: any, index: number) => {
        return { id: index + 1, message: el }
      })

      const educatorsFeedback = responseJson.report.attributes["360_feedback"].educators.map((el: any, index: number) => {
        return { id: index + 1, message: el }
      })

      const updatedRatings = ratings.map((ratingItem) => ({
        ...ratingItem,
        rating: responseJson.report.attributes.behaviour[ratingItem.key] || ratingItem.rating
      }));

      this.handleInputChange({ target: { value: course[0]?.attributes.title } }, "course");
      this.handleInputChange({ target: { value: school[0]?.attributes.name } }, "school");
      this.setState({
        selectedGradesAttendanceFirst: [...attendanceFirst],
        academic_year: responseJson.report.attributes.academic_year,
        programTrack: responseJson.report.attributes.program_track,
        studentsFeedback: studentFeedback,
        parentsFeedback: parentsFeedback,
        educatorsFeedback: educatorsFeedback,
        recommendations: responseJson.report.attributes.recommendations_remarks,
        acknowledgement: responseJson.report.attributes.acknowledgement,
        ratings: updatedRatings,
      })
    }
  }

  goBack = () => {
    if (this.state.currentPage === "TprList") {
      this.props.navigation.goBack();
    } else {
      this.setState({ currentPage: "TprList", editMode: false });
      this.handleClearState();
      this.refreshPage();
    }
  };

  refreshPage = () => {
    window.location.reload();
  };

  handleCreateTprClick = () => {
    this.handleClearState();
    this.setState({ currentPage: "form" })
  }

  handleInputChange(event: any, targetTpr: string) {
    switch (targetTpr) {
      case configJSON.TimelyProgress.Course:
        const courseData = this.state.schoolCourseData.mind_mastery_courses
        const idOverviewTpr = this.state.schoolCourseData.mind_mastery_courses.data.filter((course: any) => {
          return course.attributes.title === event.target.value;
        })
        this.setState({
          selectedCourse: { name: event.target.value, id: idOverviewTpr[0].id, overview: idOverviewTpr[0].attributes.overview },
          validationErrors: { ...this.state.validationErrors, selectedCourse: false }
        }, () => {
          const courseName = this.state.selectedCourse.name
          const findAcademicYear = (courseReport: Courses[], title: string): string[] | null => {
            const course = courseReport.find((item: Courses) => item.attributes.title === title);
            return course ? course.attributes.academic_year : null;
          };

          const transformAcademic = (courseReport: string[]): TransformeAcademic[] =>
            courseReport.map((year: string) => ({
              title: year,
              value: year,
              id: year
            }));

          const academicYear = findAcademicYear(courseData.data, courseName);
          const transformedData = transformAcademic(academicYear as string[]);
          this.setState({ academicYearList: transformedData });
        });
        break;

      case configJSON.TimelyProgress.School:
        const school = this.state.schoolCourseData.schools.data.filter((schoolTpr: any) => {
          return schoolTpr.attributes.name === event.target.value;
        })
        this.setState({
          selectedSchool: { name: event.target.value, id: school[0]?.id },
          validationErrors: { ...this.state.validationErrors, selectedSchool: false }
        }, () => this.getGradeList()
        );
        break;

      case configJSON.TimelyProgress.Year:
        this.setState({ academic_year: event.target.value, validationErrors: { ...this.state.validationErrors, academicYear: false } });
        break;

      case configJSON.TimelyProgress.ProgramTrackKey:
        if (event.target.value.length < 1440) {
          this.setState({ programTrack: event.target.value, validationErrors: { ...this.state.validationErrors, programTrack: false, programTrackerLength: false } });
        }
        else {
          this.setState({ validationErrors: { ...this.state.validationErrors, programTrackerLength: true } });
        }
        break;
    }
  }

  handleAttendanceFirstChange(event: any, id: any, attendenceType: any) {
    let attendanceToUpdate: any;
    let updatedArray: any;
    switch (attendenceType) {
      case configJSON.TimelyProgress.AttendanceFirstDropDown:
        attendanceToUpdate = this.state.selectedGradesAttendanceFirst.find((module: any) => module.id === id);
        attendanceToUpdate.value = event.target.value;
        updatedArray = this.state.selectedGradesAttendanceFirst.map((module: any) => (module.id === id ? attendanceToUpdate : module));
        this.setState({ selectedGradesAttendanceFirst: [...updatedArray], validationErrors: { ...this.state.validationErrors, storisOfImpact: false } });
        break;
      case configJSON.TimelyProgress.AttendanceFirstInput:
        attendanceToUpdate = this.state.selectedGradesAttendanceFirst.find((module: any) => module.id === id);
        const regex = /^(100|[1-9]?\d)$/;
        const isMatch = regex.test(event.target.value);
        attendanceToUpdate.attendance = isMatch ? event.target.value : event.target.value.slice(0, -1);
        updatedArray = this.state.selectedGradesAttendanceFirst.map((module: any) => (module.id === id ? attendanceToUpdate : module));
        this.setState({ selectedGradesAttendanceFirst: [...updatedArray], validationErrors: { ...this.state.validationErrors, attendanceFirst: false } });
        break;
    }

  }

  handleEducatorMessageChange(event: any, id: any, field: string) {
    switch (field) {
      case configJSON.TimelyProgress.Students:
        this.handleTimelyProgress(event, id)
        break;
      case configJSON.TimelyProgress.Parents:
        this.handleTimelyProgressParent(event, id)
        break;
      case configJSON.TimelyProgress.Educators:
        this.handleTimelyProgressEducator(event, id)
        break;
    }
  }

  handleTimelyProgress = (event: React.ChangeEvent<HTMLInputElement>, idValues: number) => {
    let messageToUpdate = this.state.studentsFeedback.find((message: { id: number }) => message.id === idValues);
    let updatedArray: Array<{ id: number, message: string }>;

    if (event.target.value.length < 170) {
      messageToUpdate.message = event.target.value;
      updatedArray = this.state.studentsFeedback.map((message: { id: number }) => (message.id === idValues ? messageToUpdate : message));
      this.setState({ studentsFeedback: [...updatedArray], validationErrors: { ...this.state.validationErrors, studentsFeedback: false, studentFeedbackCharacter: false, studentFeedbackLength: false } });
    }
    else {
      this.setState({ validationErrors: { ...this.state.validationErrors, studentFeedbackCharacter: true, studentFeedbackLength: false } });
    }
  }

  handleTimelyProgressParent = (event: React.ChangeEvent<HTMLInputElement>, parentId: number) => {
    let messageToUpdate = this.state.parentsFeedback.find((message: { id: number }) => message.id === parentId);
    let updatedArray: Array<{ id: number, message: string }>;
    if (event.target.value.length < 170) {
      messageToUpdate.message = event.target.value;
      updatedArray = this.state.parentsFeedback.map((message: { id: number }) => (message.id === parentId ? messageToUpdate : message));
      this.setState({ parentsFeedback: [...updatedArray], validationErrors: { ...this.state.validationErrors, parentsFeedback: false, parentFeedbackCharacter: false, parentFeedbackLength: false } });
    } else {
      this.setState({ validationErrors: { ...this.state.validationErrors, parentFeedbackCharacter: true, parentFeedbackLength: false } });
    }
  }

  handleTimelyProgressEducator = (event: React.ChangeEvent<HTMLInputElement>, educatorId: number) => {
    let messageToUpdate = this.state.educatorsFeedback.find((message: { id: number }) => message.id === educatorId);
    let updatedArray: Array<{ id: number, message: string }>;
    if (event.target.value.length < 170) {
      messageToUpdate.message = event.target.value;
      updatedArray = this.state.educatorsFeedback.map((message: { id: number }) => (message.id === educatorId ? messageToUpdate : message));
      this.setState({ educatorsFeedback: [...updatedArray], validationErrors: { ...this.state.validationErrors, educatorsFeedback: false, educatorFeedbackCharacter: false, educatorFeedbackLength: false } });
    } else {
      this.setState({ validationErrors: { ...this.state.validationErrors, educatorFeedbackCharacter: true, educatorFeedbackLength: false } });
    }
  }

  handleAddNewAttendance() {
    let newId = this.state.selectedGradesAttendanceFirst.length + 1;
    let uniqId = this.generateUniqueAlphanumericString();
    this.setState({ selectedGradesAttendanceFirst: [...this.state.selectedGradesAttendanceFirst, { id: newId, value: "", key: uniqId, attendance: "" }] })
  }

  handleRemoveAttendance(key: string) {
    const updatedAttendanceFirst = [...this.state.selectedGradesAttendanceFirst].filter((attendance: AttendanceRecord) => attendance.key !== key);
    this.setState({ selectedGradesAttendanceFirst: updatedAttendanceFirst });
  }

  calculateAverageAttendance(data: AttendanceRecord[]): Result {
    const gradeAttendance: { [key: string]: GradeAttendance } = {};

    data.forEach(item => {
      const grade = item.value.split('-')[0];
      if (!gradeAttendance[grade]) {
        gradeAttendance[grade] = { total: 0, count: 0 };
      }

      gradeAttendance[grade].total += parseInt(item.attendance, 10);
      gradeAttendance[grade].count++;
    });
    const result: Result = {};
    for (const grade in gradeAttendance) {
      const averageAttendance = gradeAttendance[grade].total / gradeAttendance[grade].count;
      result[`grade_${grade}`] = averageAttendance;
    }

    return result;
  }

  handleCreateClick() {
    let attendance_percent_per_grade = this.state.selectedGradesAttendanceFirst.reduce((acc: any, item: any) => {
      if (item.attendance) {
        acc[item.value] = parseInt(item.attendance);
      }
      return acc;
    }, {});

    let attendance_percent = this.calculateAverageAttendance(this.state.selectedGradesAttendanceFirst);

    let behaviour = this.state.ratings.reduce((acc: any, item: any) => {
      acc[item.key] = parseInt(item.rating);
      return acc;
    }, {});

    let studentsFeedback = this.state.studentsFeedback.map((module: any) => {
      return module.message;
    })
    let parentsFeedback = this.state.parentsFeedback.map((module: any) => {
      return module.message;
    })
    let educatorsFeedback = this.state.educatorsFeedback.map((module: any) => {
      return module.message;
    })

    let body = {
      program_track: this.state.programTrack,
      school_id: parseInt(this.state.selectedSchool.id),
      mind_mastery_course_id: parseInt(this.state.selectedCourse.id),
      academic_year: this.state.academic_year,
      attendance_percent_per_grade: attendance_percent_per_grade,
      attendance_percent: attendance_percent,
      behaviour: behaviour,
      "360_feedback": {
        students: studentsFeedback,
        parents: parentsFeedback,
        educators: educatorsFeedback
      },
    }

    if (this.validateForm()) {
      const header = {
        "Content-Type": configJSON.dashboardContentType,
        Authorization: this.state.user_token
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.PostSubmitFormapiCallId = apiRequest.messageId;
      this.makeApiCallListTpr(
        apiRequest.messageId,
        this.state.editMode ? configJSON.updateMethod : configJSON.dashboardPostApiMethod,
        this.state.editMode ? configJSON.timelyProgressReportEndPoint + `/${this.state.editReportId}` : configJSON.timelyProgressReportEndPoint,
        header,
        JSON.stringify(body)
      );
    }
  }

  validateForm() {
    let updateErrorStatus = {
      selectedCourse: false,
      selectedSchool: false,
      academicYear: false,
      programTrack: false,
      programTrackerLength: false,
      moduleNames: false,
      recommendations: false,
      acknowledgement: false,
      attendanceFirst: false,
      attendanceSecond: false,
      studentsFeedback: false,
      parentsFeedback: false,
      educatorsFeedback: false,
      studentFeedbackLength: false,
      parentFeedbackLength:false,
      educatorFeedbackLength: false,
      studentFeedbackCharacter: false,
      parentFeedbackCharacter: false,
      educatorFeedbackCharacter: false,
    }
    if (this.state.selectedCourse.name === "") {
      updateErrorStatus.selectedCourse = true
    }
    if (this.state.selectedSchool.name === "") {
      updateErrorStatus.selectedSchool = true
    }
    const regex = /^\d{4}-\d{4}$/;
    const isMatch = regex.test(this.state.academic_year.trim());
    if (!isMatch) {
      updateErrorStatus.academicYear = true
    }
    if (this.state.programTrack.trim() === "") {
      updateErrorStatus.programTrack = true
    }
    updateErrorStatus.programTrackerLength = this.isTextTooLong(this.state.programTrack,1440)
    const hasNoneAttendanceFirst = this.state.selectedGradesAttendanceFirst.filter((attendance: AttendanceRecord) => attendance.attendance);
    if (hasNoneAttendanceFirst.length < 1) {
      updateErrorStatus.attendanceFirst = true
    }

    const hasOneEmptyParentsFeedback = this.state.parentsFeedback.filter((el: any) => el.message?.trim() !== "");
    updateErrorStatus.parentsFeedback = this.hasNoFeedback(this.state.parentsFeedback);
    updateErrorStatus.parentsFeedback = this.checkParentsFeedback(this.state.parentsFeedback, hasOneEmptyParentsFeedback);
  

    const hasOneEmptyTrainerFeedback = this.state.educatorsFeedback.filter((el: any) => el.message?.trim() !== "");
    updateErrorStatus.educatorsFeedback = this.hasNoFeedback(this.state.educatorsFeedback);
    updateErrorStatus.educatorsFeedback = this.checkParentsFeedback(this.state.educatorsFeedback, hasOneEmptyParentsFeedback);

    const hasOneEmptyStudentFeedback = this.state.studentsFeedback.filter((el: any) => el.message?.trim() !== "");
    updateErrorStatus.studentsFeedback = this.hasNoFeedback(this.state.studentsFeedback);
    updateErrorStatus.studentsFeedback = this.checkParentsFeedback(this.state.studentsFeedback, hasOneEmptyParentsFeedback);
 
    updateErrorStatus.studentFeedbackLength = this.maxLengthFeedback(this.state.studentsFeedback, 3);
    updateErrorStatus.educatorFeedbackLength = this.maxLengthFeedback(this.state.educatorsFeedback, 3);
    updateErrorStatus.parentFeedbackLength = this.maxLengthFeedback(this.state.parentsFeedback, 3);

    updateErrorStatus.studentFeedbackCharacter = this.hasLongMessages(this.state.studentsFeedback, 170);
    updateErrorStatus.parentFeedbackCharacter = this.hasLongMessages(this.state.parentsFeedback, 170);
    updateErrorStatus.educatorFeedbackCharacter = this.hasLongMessages(this.state.educatorsFeedback, 170);

    this.setState({ validationErrors: { ...updateErrorStatus } });
    const valuesArray = Object.values(updateErrorStatus);
    const hasValidationError = valuesArray.filter((el: any) => {
      return el === true
    })
    if (hasValidationError.length < 1) {
      return true
    } else return false;
  }

  checkParentsFeedback(feedbackList: { id: number; message: string }[], hasOneEmptyFeedback: boolean): boolean {
    return feedbackList.some((feedData, msgIndex) => {
      return feedData?.message?.length === 0 && hasOneEmptyFeedback && msgIndex < 3;
    });
  }

  maxLengthFeedback(feedbacks: Array<FedbackType>, maxLength: number): boolean {
    return feedbacks.length < maxLength
  }

  hasLongMessages(feedbacks: Array<FedbackType>, maxLength: number): boolean {
    return feedbacks.some((feedback) => feedback.message?.trim().length >= maxLength);
  }

  isTextTooLong(text: string, maxLength: number): boolean {
    return text.length >= maxLength;
  }

  hasNoFeedback(feedbackArray: [{id:number, message:string}]) {
    return feedbackArray.filter((element) => element.message?.trim() !== "").length === 0;
  }

  closeSuccessModal = () => {
    this.getReportDataTpr();
    this.setState({ reportSubmittedModal: false, currentPage: "TprList" });
    this.handleClearState();
  }
  closeDeleteModal = () => {
    this.setState({ deleteModal: false })
  }

  onSnackbarClose() {
    this.setState({ snackBarIsOpen: false, snackBarMsgTpr: "" })
  }

  handleTprDeleteIconClick(id: any) {
    this.setState({ deleteModal: true, deleteReportId: id })
  }

  handleClearState() {
    this.setState({
      ...commonStateTpr, studentsFeedback: [{ id: 1, story: "" }], parentsFeedback: [{ id: 1, story: "" }], educatorsFeedback: [{ id: 1, story: "" }],
      selectedGradesAttendanceFirst: [{ id: 1, value: "", key: 'H49CH1', attendance: "" }],
    })
  }

  handleTprEditIconClick(id: any) {
    this.setState({ currentPage: "form", editMode: true, editReportId: id });
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.EditReportApiCallId = apiRequest.messageId;
    this.makeApiCallListTpr(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.timelyProgressReportEndPoint + `/${id}/edit`,
      header,
    );
  }

  handleDeleteCall() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.DeleteReportApiCallId = apiRequest.messageId;
    this.makeApiCallListTpr(
      apiRequest.messageId,
      configJSON.deleteMethod,
      configJSON.timelyProgressReportEndPoint + `/${this.state.deleteReportId}`,
      header,
    );
  }

  handleTprPageChange(page: any) {
    this.setState({ pageNo: page }, () => this.getReportDataTpr())
  }

  handleSkil1RatingChange = (value: any, skillName: string) => {
    let newRatings;
    newRatings = this.state.ratings.map((item: any) => {
      if (item?.name === skillName) {
        return { ...item, rating: value };
      }
      return item;
    });
    this.setState({ ratings: [...newRatings] });
  }

  getCourseListTpr() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequestTpr = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetCourseListApiCallId = apiRequestTpr.messageId;
    this.makeApiCallListTpr(
      apiRequestTpr.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getCourseListApiEndPoint,
      header,
    );
  }

  getGradeList() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const params = this.state.selectedSchool.id !== 0 ? { school_id: this.state.selectedSchool.id, course_id: this.state.selectedCourse.id } : undefined;
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetGradeListApiCallId = apiRequest.messageId;
    this.makeApiCallListTpr(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getGradeListEndPoint,
      header,
      undefined,
      params
    );
  }

  getReportDataTpr() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequestTpr = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetReportDataApiCallId = apiRequestTpr.messageId;
    this.makeApiCallListTpr(
      apiRequestTpr.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.timelyProgressReportEndPoint,
      header,
      undefined,
      { page: this.state.pageNo }
    );
  }

  async makeApiCallListTpr(
    uniqueApiCallIdTpr: string,
    methodTpr: string,
    endpointTpr: string,
    headersTpr: any,
    bodyTpr?: any,
    paramsTpr?: any,
  ) {
    let fullURLTpr =
      endpointTpr.indexOf("://") === -1
        ? config.baseURL + "/" + endpointTpr
        : endpointTpr;

    let apiResponseMessageTpr = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessageTpr.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallIdTpr
    );
    try {
      const actualUrl = paramsTpr ? fullURLTpr + "?" + new URLSearchParams({ ...paramsTpr }).toString() : fullURLTpr;
      let response = await fetch(actualUrl, {
        method: methodTpr.toUpperCase(),
        headers: headersTpr,
        body: bodyTpr
      });
      if (response.status === 401) {
      }
      let responseJsonTpr = await response.json();
      apiResponseMessageTpr.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonTpr
      );

      apiResponseMessageTpr.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonTpr
      );
    } catch (errorTpr) {
      runEngine.debugLog("RestApiClient Error", errorTpr);

      apiResponseMessageTpr.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessageTpr);
  }

  handleAddFeedBack() {
    if (this.state.studentsFeedback.length < 9) {
      let newId = this.state.studentsFeedback.length + 1;
      if (this.state.studentsFeedback.length > 1) {
        this.setState({ validationErrors: { ...this.state.validationErrors, studentsFeedback: false, studentFeedbackCharacter: false, studentFeedbackLength: false } });
      }
      this.setState({ studentsFeedback: [...this.state.studentsFeedback, { id: newId, message: "" }] });
    }
  }

  handleAddFeedBackParent() {
    if (this.state.parentsFeedback.length < 9) {
      let newId = this.state.parentsFeedback.length + 1;
      if (this.state.parentsFeedback.length > 1) {
        this.setState({ validationErrors: { ...this.state.validationErrors, parentsFeedback: false, parentFeedbackCharacter: false, parentFeedbackLength: false } });
      }
      this.setState({ parentsFeedback: [...this.state.parentsFeedback, { id: newId, message: "" }] });
    }
  }

  handleAddFeedBackEducator() {
    if (this.state.educatorsFeedback.length < 9) {
      let newId = this.state.educatorsFeedback.length + 1;
      if (this.state.educatorsFeedback.length > 1) {
        this.setState({ validationErrors: { ...this.state.validationErrors, educatorsFeedback: false, educatorFeedbackCharacter: false ,educatorFeedbackLength:false } });
      }
      this.setState({ educatorsFeedback: [...this.state.educatorsFeedback, { id: newId, message: "" }] });
    }
  }

  renderStudentFeedback(firstMessageError:boolean, secondMessageError:boolean) {
    return firstMessageError && secondMessageError
  }

  generateUniqueAlphanumericString(): string {
    const existingKeys = new Set(this.state.selectedGradesAttendanceFirst.map(item => item.key));
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 6;

    let result: string = '';
    let isUnique: boolean = false;

    while (!isUnique) {
      const randomValues = new Uint8Array(length);
      window.crypto.getRandomValues(randomValues);
      result = letters.charAt(randomValues[0] % letters.length);
      for (let i = 1; i < length; i++) {
          result += characters.charAt(randomValues[i] % characters.length);
      }
      isUnique = !existingKeys.has(result);
    }
    return result;
  }
  handleReportRefresh() {
    window.location.reload();
  }
  // Customizable Area End
}
