import React from 'react'
import { Typography, Select, MenuItem, IconButton } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { darkArrowDown } from "../../blocks/dashboard/src/assets";
import CloseRounded from "@material-ui/icons/CloseRounded";

export const useStyles = makeStyles<Theme,{ mb?: string; labelFontSize?: string }>((theme) => ({
    dropDown: {
        '&.MuiFormControl-root': {
            minWidth: '100%'
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        "&.MuiOutlinedInput-root": {
            width: "100%",
            height: "56px",
            backgroundColor: 'white',
            borderRadius: '8px',
            color: 'black',
            fontSize: '14px',
            [theme.breakpoints.down("xs")]: {
                 marginBottom: (props:{mb:string}) => props.mb ? props.mb : '0px'
            }
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            paddingLeft: '18px'
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            paddingRight: '2px',
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 60px)',
            textTransform: 'capitalize',
            '&:hover': {
                width: '100%'
            }
        }
    },
    menuItems: {
        "&.MuiMenuItem-root": {
            display: "flex",
            width: "100%"
        },
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: '#0056a314'
        },
        '&.MuiListItem-root:hover': {
            backgroundColor: '#0056a314'
        }
    },
    dropDownWrapper: {
        marginBottom: '40px'
    },
    fieldLabel: {
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: '7px',
        [theme.breakpoints.up("sm")]: {
            fontSize: (props:{labelFontSize:string}) => props.labelFontSize ? props.labelFontSize : '20px',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        }
    },
    menu: {
        "& .MuiPopover-paper": {
            border: "1px solid #066CD2",
            boxShadow: "0px 10px 16px rgba(165, 176, 194, 0.15)",
            borderRadius: "8px",
            maxHeight: '155px',
        },
        '& .MuiPopover-paper.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
            overflow: 'hidden auto'
        }
    },
    paper: {
        marginTop: "53px",
        [theme.breakpoints.down("xs")]: {
            marginTop: '59px'
        }
    },
    placeholderPaper: {
        marginTop: "46px"
    }
}))

const usePlaceholderStyles = makeStyles(() => ({
    placeholder: {
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        opacity: 0.5907738208770752
    }
}));

type PlaceholderProps = { children: React.ReactNode, placeholderStyle?: string };

export const Placeholder: React.FC<PlaceholderProps> = (props) => {
    const classes = usePlaceholderStyles();
    return <div data-test-id='box' className={props.placeholderStyle ? props.placeholderStyle : classes.placeholder}>{props.children}</div>;
};

export interface MenuList {
    id: number | string,
    value: string | number,
    title: string,
    image?: string | undefined | number;
}

interface Props {
    mb?: string;
    error?: boolean;
    labelFontSize?: string;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    iconComponentIcon?: string| React.ElementType;
    startAdornment?: React.ReactNode;
    selectStyle?: string;
    menuItemStyle?: string;
    placeholderStyle?: string;
    iconImageStyle?:  React.CSSProperties;
    paperStyle?: string;
    menuStyle?: string;
    menuItemImageStyle?: React.CSSProperties;
    noDataPlaceholder?: string;
    label?: string;
    value?: string | number;
    menuList: MenuList[];
    name?: string;
    disabled?: boolean;
    placeholder?: string;
    IconComponent?: React.ElementType;
    onClear? : () => void
}

const CommonSelect = (props: Props) => {
    const classes = useStyles(props);
    const crossIcon = props.onClear ? <IconButton disabled={props.disabled} onClick={props.onClear} ><CloseRounded /></IconButton> : <img className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' style={props.iconImageStyle || webStyles.arrowDownImage} src={props.iconComponentIcon || darkArrowDown} alt="" />;
    // className is added here to make it clickable
    const iconComponent = () => props.value != "" ? crossIcon : <img className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' style={props.iconImageStyle || webStyles.arrowDownImage} src={props.iconComponentIcon || darkArrowDown} alt="" />;
    const isMenuNotEmpty = props.menuList?.length > 0;
    return <>
        {props.label && <Typography className={classes.fieldLabel}>{props.label}</Typography>}
        <Select
            className={props.selectStyle || classes.dropDown}
            onChange={props.handleChange as undefined}
            margin="dense"
            variant="outlined"
            fullWidth
            error={props.error}
            disabled={props.disabled}
            name={props.name}
            IconComponent={iconComponent}
            startAdornment={props.startAdornment}
            value={props.value}
            displayEmpty
            renderValue={
                props.value !== "" ? undefined : () => (<Placeholder placeholderStyle={props.placeholderStyle} ><>{props.placeholder || ""}</></Placeholder>)
            }
            MenuProps={{ className: props.menuStyle || classes.menu, PopoverClasses: { paper: isMenuNotEmpty ? (props.paperStyle || classes.paper) : (props.noDataPlaceholder || classes.placeholderPaper) } }}
            data-test-id='filter-select'
        >
            {isMenuNotEmpty ? props.menuList.map((element) => {
                return <MenuItem key={element.id} className={props.menuItemStyle || classes.menuItems} value={element.value}>
                    {element.image && <img style={props.menuItemImageStyle || webStyles.menuItemImg} src={String(element.image)} alt="" />}
                    <Typography>{element.title}</Typography>
                </MenuItem>
            }) : <MenuItem className={props.menuItemStyle || classes.menuItems} disabled>No item to select</MenuItem>}
        </Select>
    </>
}
export default CommonSelect;

export const webStyles = {
    arrowDownImage: {
        width: "16px",
        height: "9px",
        top: '25px',
        right: "27px"
    },
    menuItemImg: {
        marginRight: '8px',
        width: '40px',
        height: '40px'
    },
    imgTextWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
}